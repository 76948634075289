<template>
  <div>
    <Header />
    <div class="page-title">
      <div class="container">
        <div class="title text-black">Empresa</div>
      </div>
    </div>
    <section class="one pt-2-mobile">
      <div class="container">
        <div class="block desktop6 tablet6 pr-1-tablet pr-1-desktop mobile12">
          <p class="text-desc">
            A Tradecorp oferece um amplo portfólio de fertilizantes,
            biofertilizantes/bioestimulantes, inoculantes, adjuvantes e
            reguladores de crescimento que respondem às tendências atuais do
            mercado global adaptados às condições agronômicas locais. A nova
            geração de seus produtos garante uma produção sustentável, alto
            rendimento e extrema qualidade em todo o mundo.
          </p>
          <p class="text-desc mt-1">
            Sediada em Hortolândia, interior do estado de São Paulo, a Tradecorp
            possui também um centro de distribuição logística, localizado no
            condomínio de galpões GR Hortolândia, às margens da Rodovia dos
            Bandeirantes, aproximadamente a 16 km de Campinas e 20 km de Sumaré
            e uma filial em Monte Mor (SP) destinada à fabricação e distribuição
            de seus diferenciados produtos, desenvolvidos por meio de
            conhecimento e da melhor tecnologia nacional e internacional.
          </p>
          <p class="text-desc mt-1">
            Dentro de um plano de responsabilidade socioambiental, a Tradecorp é
            associada e acionista do Instituto Nacional de Processamento de
            Embalagens Vazias (inpEV), que visa dar destinação correta às
            embalagens de agroquímicos recolhidas junto aos agricultores. Também
            utiliza, em quase a totalidade de seu portfólio, embalagens da Campo
            Limpo, uma indústria do Sistema inpEV, que utiliza como
            matéria-prima o material recolhido do campo, fechando o ciclo e
            trazendo mais sustentabilidade para a operação no Brasil.
          </p>
          <p class="text-desc mt-1">
            A companhia também destina recursos ao desenvolvimento social,
            apoiando importantes instituições brasileiras de assistência à
            criança e ao adolescente, como o Centro Infantil Boldrini e a
            Fundação ABRINQ.
          </p>
        </div>
        <div class="block desktop6 tablet6 mobile12 pl-2-desktop mt-3-mobile">
          <div class="slider">
            <div
              id="bannerSlider"
              class="swiper-container"
              style="overflow: hidden"
            >
              <div class="swiper-wrapper">
                <div
                  v-for="banner in banners"
                  :key="banner.id"
                  class="swiper-slide banner"
                >
                  <div
                    class="img-bg"
                    :style="`background-image:url(${banner.image})`"
                  ></div>
                  <span>{{ banner.label }}</span>
                </div>
              </div>
              <!-- <div
                v-show="banners.length > 1"
                class="swiper-pagination banner-pagination inset"
              ></div> -->
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="microquimica-tradecorp">
      <div class="container">
        <div
          class="block desktop12 tablet12 mobile12 pl-2-desktop pl-1-tablet mt-3-mobile"
        >
          <h1 class="title text-white">Tradecorp</h1>
          <div class="desc-holder">
            <p class="text-desc text-white">
              A Tradecorp é uma empresa espanhola fundada em 1985, especialista
              no desenvolvimento e produção de soluções para nutrição e
              bioestimulação de plantas, cujo objetivo é aumentar, de maneira
              sustentável, a produtividade das culturas.
            </p>
            <p class="text-desc text-white mt-1">
              Em 2000, a Tradecorp passou a fazer parte do Grupo ROVENSA, uma
              holding portuguesa fundada em 1926 e nascida da mineração de
              pirita no sul de Portugal e que iniciou suas atividades agrícolas
              graças a utilização de um sub-produto da mineração na fabricação
              de fertilizantes (www.rovensa.com).
            </p>
            <p class="text-desc text-white mt-1">
              A nutrição especializada de plantas ganhou mais importância como
              ferramenta no aumento da performance e qualidade e, em 2002,
              nasceu a Tradecorp Brasil, contando com um amplo portfólio e uma
              equipe de colaboradores preparados para assessorar distribuidores
              e produtores agrícolas, solucionando dificuldades de diversas
              culturas em diferentes regiões do país.
            </p>
            <p class="text-desc text-white mt-1">
              Em 2017, após um acordo de venda, a unidade de negócios agrícolas
              do grupo é adquirida pelo Fundo de Investimentos internacional
              BRIDGEPOINT, com o objetivo de abrir novos mercados, acelerar o
              crescimento e o desenvolvimento de novas soluções para agricultura
              (www.bridgepoint.eu).
            </p>
            <p class="text-desc text-white mt-1">
              Resultado do crescimento de uso de produtos microbiológicos ou
              derivados de seus metabolismos, a TRADECORP, empresa especialista
              em microrganismos e biotecnologia para a proteção e bioestimulação
              de plantas, é adquirida em 2019 e torna-se o mais novo pilar do
              grupo ROVENSA.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="gallery">
      <div class="gallery-holder">
        <div
          v-for="(item, index) in gallery"
          :key="`gallery${index}`"
          class="gallery-item"
        >
          <div
            class="holder"
            @click="toggleFullscreen(index)"
            :style="`background-image:url(${item.image})`"
          ></div>
        </div>
      </div>
    </section>

    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div
        v-show="showFullscreenSlider"
        @click.self="closeFullscreenSlider"
        class="fullscreen-slider"
      >
        <span @click="closeFullscreenSlider" class="close">X</span>
        <div id="fullscreenSlider" class="swiper-container">
          <div class="swiper-wrapper">
            <div
              v-for="(item, index) in gallery"
              @click.self="closeFullscreenSlider"
              :key="`fullscreenSlider${index}`"
              class="swiper-slide fullscreen-image"
            >
              <img :src="item.image" alt="Tradecorp" />
            </div>
          </div>
          <div id="fullscreenNext">
            <font-awesome-icon :icon="['fas', 'chevron-right']" />
          </div>
          <div id="fullscreenPrev">
            <font-awesome-icon :icon="['fas', 'chevron-left']" />
          </div>
          <div class="swiper-pagination full-screen-pagination"></div>
        </div>
      </div>
    </transition>

    <section class="video">
      <div class="container">
        <div class="block desktop4 tablet5 mobile12 pr-2-desktop pr-1-tablet">
          <h1 class="title text-white">AMPLIAÇÃO E MODERNIZAÇÃO</h1>
          <p class="text-desc text-white mt-1">
            Confira o vídeo da inauguração das novidades na fábrica de Produtos
            Biológicos da Tradecorp.
          </p>
        </div>
        <div
          class="block video desktop8 tablet7 mobile12 pl-1-desktop mt-2-mobile"
        >
          <iframe
            src="https://www.youtube.com/embed/Lv1rW41MPUI"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>
<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Swiper, { Navigation, Pagination, Autoplay } from "swiper";
Swiper.use([Navigation, Pagination, Autoplay]);
import "swiper/swiper-bundle.min.css";
export default {
  name: "Empresa",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      banners: [
        {
          id: "9i324ker",
          image: require("../assets/images/empresa1.jpg"),
          label: "Moderna Unidade de Produtos Biológicos",
        },
        {
          id: "2oekrmf",
          image: require("../assets/images/empresa2.jpg"),
          label: "Fermentação Biológica de Inoculantes",
        },
        {
          id: "dsafvc",
          image: require("../assets/images/empresa3.jpg"),
          label: "Sistema de Controle de Processo",
        },
        {
          id: "456yt",
          image: require("../assets/images/empresa4.jpg"),
          label: "Rígido Controle de Qualidade",
        },
      ],
      gallery: [
        {
          image: require("../assets/images/empresa1.jpg"),
        },
        {
          image: require("../assets/images/empresa2.jpg"),
        },
        {
          image: require("../assets/images/empresa3.jpg"),
        },
        {
          image: require("../assets/images/empresa4.jpg"),
        },
        {
          image: require("../assets/images/gallery5.jpg"),
        },
        {
          image: require("../assets/images/gallery6.jpg"),
        },
        {
          image: require("../assets/images/Tradecorp02.jpg"),
        },
        {
          image: require("../assets/images/gallery8.jpg"),
        },
      ],
      fullscreenSlider: null,
      showFullscreenSlider: false,
    };
  },
  mounted() {
    this.startBannerSlider();
  },
  methods: {
    closeFullscreenSlider() {
      this.showFullscreenSlider = false;
      this.fullscreenSlider.destroy();
    },
    toggleFullscreen(imageIndex) {
      this.showFullscreenSlider = true;
      console.log(imageIndex);
      setTimeout(() => {
        this.fullscreenSlider = new Swiper("#fullscreenSlider", {
          autoplay: false,
          slidesPerView: 1,
          loop: true,
          initialSlide: imageIndex,
          pagination: {
            el: ".full-screen-pagination",
            clickable: true,
          },
          navigation: {
            nextEl: "#fullscreenNext",
            prevEl: "#fullscreenPrev",
          },
        });
      }, 100);
    },
    startBannerSlider() {
      setTimeout(() => {
        this.slider = new Swiper("#bannerSlider", {
          autoplay: { delay: 6000 },
          speed: 450,
          slidesPerView: 1,
          spaceBetween: 0,
          grabCursor: true,
          loop: true,
          pagination: {
            el: ".banner-pagination",
          },
        });
      }, 250);
    },
  },
};
</script>
<style lang="scss" scoped>
section.one .container {
  display: flex;
  flex-wrap: wrap;
}

.swiper-slide.banner {
  position: relative;
  height: 480px;
  .img-bg {
    position: relative;
    width: 100%;
    height: 90%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  span {
    position: absolute;
    display: block;
    width: 80%;
    bottom: 27px;
    padding: 12px 25px;
    background-color: #fff;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-top: 2px solid var(--yellow1);
    text-align: center;
    font-size: 1.2em;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  }
}

.swiper-pagination.inset {
  bottom: 0;
}

section.microquimica-tradecorp {
  background-image: url("../assets/images/Tradecorp02.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  &::after {
    position: absolute;
    content: "";
    width: 100vw;
    height: 100%;
    background-color: var(--bg-green);
    top: 0;
    left: 0;
  }
  .container {
    display: flex;
    flex-wrap: wrap;
    .block {
      z-index: 2;
    }
  }
}

.desc-holder {
  position: relative;
  padding: 10px 0 10px 10px;
  margin-top: 25px;
  border-left: 2px solid var(--yellow1);
}

.gallery-holder {
  position: relative;
  width: 95%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20%, 0.33fr));
  gap: 2rem;
  justify-content: space-around;
  .gallery-item {
    position: relative;
    padding-bottom: 95%;
    .holder {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      transition: transform 0.4s ease;
      cursor: pointer;
      &:hover {
        transform: scale(1.05);
      }
    }
  }
}

section.video {
  background-color: var(--bg-green);
  .container {
    display: flex;
    flex-wrap: wrap;
    .block {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
    .block.video {
      position: relative;
      iframe {
        position: relative;
        display: block;
        width: 100%;
        height: 480px;
      }
    }
  }
}

.fullscreen-slider {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.85);
  top: 0;
  left: 0;
  z-index: 999;
  .close {
    position: absolute;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 1.4em;
    color: #fff;
    top: 2.2rem;
    right: 3.5rem;
    z-index: 9;
    cursor: pointer;
  }

  .fullscreen-image {
    position: relative;
    width: 95vw;
    height: 95vh;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      position: relative;
      display: block;
      max-width: 95%;
      max-height: 95%;
    }
  }

  #fullscreenNext,
  #fullscreenPrev {
    position: fixed;
    width: 38px;
    height: 38px;
    border: 2px solid #fff;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    top: 50%;
    margin: 0;
    left: 1.5rem;
    opacity: 0.7;
    transition: opacity 0.25s ease;
    &:hover {
      opacity: 1;
    }
    cursor: pointer;
    svg {
      font-size: 1.4em;
      color: #fff;
    }
  }
  #fullscreenNext {
    left: initial;
    right: 1.5rem;
  }
}

@media screen and(max-width: 720px) {
  section.microquimica-tradecorp {
    &::before {
      display: none;
    }
    &::after {
      width: 100vw;
    }
  }

  section.gallery {
    padding: 35px 0;
  }
  .gallery-holder {
    width: 90%;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1rem;
  }

  .block.video iframe {
    height: 210px !important;
  }
}

@media only screen and (min-width: 721px) and (max-width: 1139px) {
  .block.video iframe {
    height: 290px !important;
  }
}
</style>
